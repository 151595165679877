module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-theme-i18n/gatsby-browser.js'),
      options: {"plugins":[],"defaultLang":"en","configPath":"/home/piotr/projects/vnlab/morality-and-sensation/i18n/config.json"},
    },{
      plugin: require('../node_modules/gatsby-theme-i18n-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"locales":"./i18n/locale","i18nextOptions":{"ns":["common","404","home","nav-menu","search","fullscreen"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[{"resolve":"/home/piotr/projects/vnlab/morality-and-sensation/plugins/remark-vnlab-plugin","name":"remark-vnlab-plugin","id":"84e30652-f908-53ee-b977-ae526c0a16b6","version":"0.0.1","modulePath":"/home/piotr/projects/vnlab/morality-and-sensation/plugins/remark-vnlab-plugin/index.js","pluginOptions":{"plugins":[]},"nodeAPIs":["createSchemaCustomization"],"browserAPIs":[],"ssrAPIs":[]},{"resolve":"/home/piotr/projects/vnlab/morality-and-sensation/node_modules/gatsby-remark-orphans","id":"d5fbd8a4-985b-55e5-ad01-89cf43e7c035","name":"gatsby-remark-orphans","version":"1.0.7","modulePath":"/home/piotr/projects/vnlab/morality-and-sensation/node_modules/gatsby-remark-orphans/dist/index.js","pluginOptions":{"plugins":[],"silenceReporting":true,"disableDefaultLocaleResolver":true},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]}],"rehypePlugins":[null],"extensions":[".mdx"],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/home/piotr/projects/vnlab/morality-and-sensation","commonmark":false,"JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"custom":{"families":["HK-Grotesk:n3,n4,n5,n7"],"urls":["/fonts/fonts.css"]},"google":{"families":["Crimson Pro:300,400,500,600,700,800,900&display=swap"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Morality and Sensation. Notes on the aesthetic and non-aesthetic experience of art","short_name":"Morality and Sensation. Notes on the aesthetic and non-aesthetic experience of art","description":"Do our beliefs influence the reception of art? Authors of the book “Morality and Sensation” seek answers using eye-tracking, surveys and phenomenology.","start_url":"/","lang":"en","background_color":"#ffffff","theme_color":"#000000","display":"standalone","orientation":"portrait","icon":"/home/piotr/projects/vnlab/morality-and-sensation/config/../static/images/favicon3.svg","cache_busting_mode":"none","localize":[],"legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../plugins/gatsby-plugin-sw/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/*"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
